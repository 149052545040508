import React from "react"
import { Card, CardTitle, CardBody, Form, FormGroup, CardText } from "reactstrap"
import { graphql } from "gatsby"
import { Link } from "gatsby"

//This is used for horizontal line
const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 3
      }}
  />
);

const ColoredThinLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1
      }}
  />
);
const WoodworkSidebar = () => (
  <div>
    <Card>
      <CardBody>
        <CardTitle className="text-center text-uppercase mb-3">
          <b>Recommended</b>
          <ColoredLine/>
        </CardTitle>
        
        
        <Form className="text-center">
          {/* <FormGroup>
            <Input
              type="email"
              name="email"
              placeholder="Your email address.."
            />
          </FormGroup>
          <button className="btn btn-outline-success text=uppercase">
            Subscribe
          </button> */}
          
         <FormGroup>
          <Link to="/how-to-select-wood-for-interior-design-work" activeStyle={{ color: "red" }}>घर के इंटीरियर के लिए लकड़ी का चुनाव कैसे करे</Link>
          </FormGroup>
          <ColoredThinLine/>
        <FormGroup>
          <Link to="/wardrobe-on-window-wall" activeStyle={{ color: "red" }}>खिड़की वाले दिवार पे अलमीरा की प्लानिंग कैसे करे ?</Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="/pvc-pvc-laminates" activeStyle={{ color: "red" }}>PVC लेमिनेट्स</Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="/vs-plywood-vs-blockboard" activeStyle={{ color: "red" }}>प्लाईवुड vs ब्लॉकबॉर्ड - किसका इस्तेमाल हमे कब करना चाहिए</Link>
          </FormGroup>
          <ColoredThinLine/>

          <FormGroup>
          <Link to="/mdf-where-to-use-mdf-in-interior-designing" activeStyle={{ color: "red" }}>MDF का इस्तेमाल कहाँ पर करना चाहिए ?</Link>
          </FormGroup>
          <ColoredThinLine/>

          <FormGroup>
          <Link to="/benefit-of-sliding-door-wardrobes" activeStyle={{ color: "red" }}>स्लाइडिंग डोर वार्डरॉब के फायदे</Link>
          </FormGroup>
          <ColoredThinLine/>

    
        </Form>
        
        <ColoredLine color="red" />
        <Form className="text-center" color='gray'>

        <CardTitle className="text-center text-uppercase mb-3">
          <b>Video reference links</b>
        </CardTitle>
          <FormGroup>
          <Link to="https://youtu.be/-JY9dfVo5dQ" activeStyle={{ color: "red" }}>Nano White/G5 Stone -  क्या बेस्ट ऑप्शन है किचन काउंटर टॉप के लिए  </Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="https://youtu.be/D_nwJL-w82w" activeStyle={{ color: "red" }}>Toughened/Backpainted गिलास इन किचन  </Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="https://youtu.be/EOxjSlpGXc8" activeStyle={{ color: "red" }}>घर के Interior के लिए लकड़ी का चुनाव कैसे करे</Link>
          </FormGroup>
          <ColoredThinLine/>
          {/* <div className="embed-responsive embed-responsive-16by9">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/srmC-fbVPQo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </div> */}
          <FormGroup>
          <Link to="https://youtu.be/ne_ldHj1BNU" activeStyle={{ color: "red" }}>False ceiling कराने से पहले ये वीडियो जरूर देखे</Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="https://youtu.be/JEm31IXPk_Y" activeStyle={{ color: "red" }}>घर के लिए Interior Design की प्लानिंग कैसे करे</Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="https://youtu.be/ffCkeHqvrSQ" activeStyle={{ color: "red" }}>PVC Laminates क्या बेस्ट ऑप्शन है ?</Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="https://youtu.be/J67m8QGWYRA" activeStyle={{ color: "red" }}>Gyspum vs POP False ceiling </Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="https://youtu.be/Y5OBpz1UE2Q" activeStyle={{ color: "red" }}>Modular Kitchen - कलर कैसे चुनें</Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="https://youtu.be/EnKPQvgVs7k" activeStyle={{ color: "red" }}>Small Bedroom Design कैसे करें</Link>
          </FormGroup>
          
        </Form>
        {/* <Link to="/tags" activeStyle={{ color: "red" }}>Tags </Link> */}
        <tagPost/>
        <tagPage/>
        <ColoredLine color="red" />

        <CardTitle className="text-center text-uppercase mb-3">
        <b>Kitchen Calculator</b>
        </CardTitle>
        <button className="btn btn-outline-success text=uppercase" >
        Kitchen
          </button>
        
      </CardBody>
    </Card>
  </div>
)

const sidebarQuery = graphql`
  query sidebarQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default WoodworkSidebar

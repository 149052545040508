import React from "react"
import Layout from '../components/layout'
import SEO from "../components/seo"

import {Container, Row, Col} from "react-bootstrap"

import Woodworksidebar from "../components/woodworksidebar"

const Woodwork = () => (
  <Layout>
  <SEO title="Wood Work" keyword={['Woodwork in hindi','Wood Selection','Lakdi ka chunao']} />

  <Container>
      <h1>Coming Soon...</h1>
      <Row>
        
        <Col md="9">
        {/* <center><h3>False Ceiling Area Calculator</h3></center>
        <FCSimpleCalculator/>
        <center><h3>False Ceiling Budget Calculator</h3></center>
        <FalseCeilingCalculator/> */}

        

        </Col>
        <Col md="3">
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.4)",
            }}
          >
            <Woodworksidebar/>
          </div>
        </Col>
      </Row>
    </Container>

  {/* <Container>
    <center><h2>Wood Work</h2></center>
    <Row>
      <Col lg={6}>
       Wood Selection
      </Col>
      <Col lg={6}>
        <p><strong>Wood selection</strong></p>
        <div className="embed-responsive embed-responsive-16by9">
       
        </div>
      </Col>
    </Row>
    <Row>
    <Col lg={6}>
       White Kitchen Countertop
      </Col>
      <Col lg={6}>
        <p><strong>G5 granite</strong></p>
        <div className="embed-responsive embed-responsive-16by9">
       
        </div>
      </Col>
    </Row>
    <Row>
    <Col lg={6}>
      Thoughend glass in Kitchen
      </Col>
      <Col lg={6}>
        <p><strong>Backpainted glass</strong></p>
        <div className="embed-responsive embed-responsive-16by9">
        
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
      <p><b>Workwork Budget Planning</b></p>
      </Col>
    </Row>
  </Container> */}
  
</Layout>
  )

  
export default Woodwork